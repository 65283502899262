body {
  background: linear-gradient(0deg, #7e8461 0%, #eaeaea 100%) fixed;
}
/* background: linear-gradient(
      0deg,
      rgb(93, 51, 100) 0%,
      rgb(135, 127, 139) 100%
    )
    fixed;
} */

/*  NAV BAR */

.navbar.navbar-expand-lg.navbar-light {
  margin-left: 1em;
  margin-right: 1em;
  top: 0em;
  position: sticky;
  border: 3px solid #887080;
  background: #eaeaea;
  border-radius: 1em;
}

.navbar-brand > img {
  height: 8vh;
}

.navs {
  align-content: flex-end;
  width: 100%;
}

/* ABOUT SECTION */

.about {
  margin-top: 3em;
  border: 3px solid #887080;
  border-radius: 1em;
  max-height: 45vh;
  overflow: auto;
}

.about-content {
  display: flex;
  margin: 1.5vw;
}

.about-text {
  margin: 1vw;
  width: 55vw;
}

.about-photo > img {
  margin: 1vw;
  height: 10em;
  margin-right: 2vw;
  margin-top: 2vw;
}

.about-links {
  margin-top: 2.25vw;
}

.about-links > .link > .alink {
  width: 3em;
  height: 3em;
  padding: 0.25vw;
}

/* SKILL CARDS */

.card.skill {
  min-height: 20em;
  background: transparent;
}

.skills {
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center;
  border: 3px solid #887080;
  /* 012A36  GUNMETAL?*/
  border-radius: 1em;
  padding-bottom: 1em;
}

.skills-header {
  text-align: center;
  font-size: 2em;
}

.list-group-item {
  background-color: transparent !important;
  font-size: 1em;
}

.list-group-item > svg {
  font-size: 2em;
}

/* PROJECT CARDS  */

.projects-header {
  margin: 2em;
  padding-top: 0.5em;
  text-align: center;
}

.card-text {
  text-align: center;
  margin-top: 0.5em;
}

.card.project {
  background: transparent;
  /* width: 40vw; */
}

/* .react-multi-carousel-track {
  display: flex;
} */

.card.project > .card-header {
  text-align: center;
}

.card.project > :is(img, .youtubeDemo) {
  height: 40vh;
  width: 40vw;
  margin: auto;
}

.project > .card-footer {
  background: transparent;
  text-align: center;
  font-size: 2em;
}

.carousel-container {
  padding-bottom: 2em;
  border-radius: 1em;
}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: calc(4% + -50px);
}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: calc(4% + -50px);
}

/* RESUME */

.react-pdf__Page {
  max-width: 99vw;
  margin: 1vw;
}

.react-pdf__Document {
  margin: 1vw;
  display: flex;
  justify-content: center;
}

.react-pdf__Page__textContent {
  border: 0.5em solid #887080;
}
